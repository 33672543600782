.main-profile-content {
  padding: 0px 34px;
  margin-top: 60px;
}

.profile-container-main {
  position: relative
}

.linkedin-img-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: #b35c45;
  padding: 5px;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  transition: background-color 0.3s;
  border: 1px solid #b35c45;
}

.linkedin-img-icon:hover {
  background-color: #b35c45;
  color: #ffffff;
}

.profile-image-main {
  width: 100%;
  height: 100%;
  display: block;
    /* margin-left: 10%; */
}

.profile-details-container-main {
  display: flex;
  align-items: center;
}

.profile-details-main {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  color: #fff;
}

.profile-name-main {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 46px;
  line-height: 1.13043em;
  text-transform: uppercase;
  word-wrap: break-word;
  color: #C79F4F;
}

.profile-position-main {
  margin-top: 4px;
  color: #C79F4F;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 300;
}

.profile-description-title-main {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  font-family: "Roboto", sans-serif;
  line-height: 27px;
}

.profile-description-main {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 27px;
  text-align: justify;
}

.awards-container-profile-main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.award-item-main {
  width: 5.25rem;
  height: auto;
}

.award-title-main {
  font-size: 14px;
  margin-top: 4px;
}

.read-more-button-main {
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #fff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
}

.read-more-button-main:hover {
  background-color: #fff;
}

.arrow-main {
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
}

.read-more-button-main:hover .arrow-main {
  transform: translate(5px, -5px);
}

.read-more-profile-btn:hover {
  color: #f9f9f9 !important;
  background-color: #051b39 !important;
}

.read-more-profile-btn {
  color: #fff !important;
  border: 1px solid #fff !important;
  border-radius: 0px !important;
  padding: 0.5rem 2rem !important;
  max-width: max-content;
  margin-bottom: 26px !important;
}

.bottom-profile-text {
  color: #f0f2f5;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 27px;
  text-align: justify;
  margin-top: 2%;
}

.education-section {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
}

.education-list {
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}

/* @media screen and (max-width: 900px) {
  .profile-image-main {
    width: 322px;
    height: 327px;
    margin-left: 5rem;
  }

  .profile-name-main {
    font-size: 41px;
  }

  .profile-description-main {
    font-size: 15px;
    line-height: 19px;
  }

  .bottom-profile-text {
    padding: 0px 119px;
    font-size: 15px;
    line-height: 21px;
    margin-left: -3rem;
  }

  .education-section {
    font-size: 19px;
  }

  .education-list {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .profile-container-main {
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }

  .profile-image-main {
    width: 90%;
    max-width: 300px;
    height: auto;
    margin-left: 0;
  }

  .profile-details-main {
    width: 90%;
    margin: 0;
    align-items: center;
    text-align: center;
  }

  .profile-name-main {
    font-size: 22px;
  }

  .profile-position-main {
    font-size: 16px;
  }

  .profile-description-main {
    padding-right: 18px;
    padding-left: 1rem;
    font-size: 14px;
  }

  .bottom-profile-text {
    padding: 1rem;
    font-size: 14px;
    margin-top: 1rem;
    margin: 30px;
    margin-top: -29px;
  }

  .education-section {
    font-size: 18px;
  }

  .education-list {
    font-size: 14px;
  }
}

@media screen and (max-width: 426px) {
  .bottom-profile-text {
    padding: 1rem;
    font-size: 14px;
    margin-top: 1rem;
    margin: 20px;
    margin-top: -14px;
  }
} */

@media screen and (max-width: 2500px) and (min-width: 1800px) {
  .profile-container-main {
    height: 100%;
  }

  .profile-description-main {
    font-size: 2rem;
    line-height: 1.5em;
  }

  .bottom-profile-text {
    font-size: 2rem;
    line-height: 1.5em;
  }

  .education-section {
    font-size: 2rem;
    line-height: 1.5em;
  }

  .education-list {
    font-size: 2rem;
    line-height: 1.5em;
  }

  .profile-name-main {
    font-size: 5.7rem;
    line-height: 1.5em;
  }

  .profile-position-main {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 1800px) and (min-width: 1600px) {
  .profile-container-main {
    height: 100%;
  }

  .profile-description-main {
    font-size: 1.7rem;
    line-height: 2.3rem;
  }

  .bottom-profile-text {
    font-size: 1.7rem;
    line-height: 2.3rem;
  }

  .education-section {
    font-size: 1.7rem;
    line-height: 2.3rem;
  }

  .education-list {
    font-size: 1.7rem;
    line-height: 2.3rem;
  }

  .profile-name-main {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 46px;
    line-height: 1.13043em;
    text-transform: uppercase;
    word-wrap: break-word;
    color: #C79F4F;
  }

  .profile-position-main {
    margin-top: 4px;
    color: #C79F4F;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 300;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1350px) {
  .profile-description-main {
    font-size: 1.5rem;
    line-height: 2.3rem;

  }

  .profile-container-main {
    height: 100%;
  }

  .bottom-profile-text {
    font-size: 1.5rem;
    line-height: 2.3rem;

  }

  .education-section {
    font-size: 1.5rem;
    line-height: 2.3rem;

  }
}