.servicespage {
  display: flex;
  flex-direction: column;
  margin: 34px;
  background-color: #f0f2f5;
  overflow: hidden;
}

.servicespage-tabs {
  display: flex;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  border-radius: 15px 15px 0 0;
}

.servicespage-tab {
  flex: 1;
  padding: 20px;
  cursor: pointer;
  text-align: center;
  font-size: 1.2rem;
  color: #333333;
  transition: background-color 0.3s ease;
  border-bottom: 4px solid transparent;
}

.servicespage-tab.active {
  background-color: #001026;
  border: 2px solid white;
  font-weight: bold;
  color: white;
}

.servicespage-tab:hover {
  background-color: #001026;
  border: 2px solid white;
  font-weight: bold;
  color: white;
}

.servicespage-content {
  padding: 40px;
  background: #ffffff;
  border-radius: 0 0 15px 15px;
  animation: fadeIn 0.5s ease-in-out;
}

.servicespage-content h2 {
  margin-top: 0;
  color: #051b39;
  font-family: Marcellus, sans-serif;
  font-weight: 400;
  font-size: 56px;
  line-height: 1.10714em;
  text-transform: uppercase;
}

.servicespage-content p {
  font-size: 1.1rem;
  color: #333333;
  line-height: 1.8;
  text-align: left;
  margin-top: 10px;
}

.services-heading{
  /* line-height: 3; */
}

.servicespage-content ul {
  padding-left: 20px;
}

.servicespage-content li {
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: #333333;
  text-align: left;
  line-height: 1.8;
}

/* @keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
} */

.services-container {
    text-align: center;
    /* background-color: #f5f5f5; */
    /* border-radius: 8px; */
    width: 100vw;
    /* padding: 0; */
    margin: 0;
    border-radius: 0px;
  }
  
  .services-container h1 {
    /* font-size: 2.5rem;
    margin-bottom: 10px;
    color: #333; */
        /* font-size: 2.5rem; */
      /* font-weight: bold; */
      margin: 0px;
      color: #C79F4F;
      font-family: "Roboto",sans-serif;
      font-weight: 400;
      font-size: 56px;
      line-height: 1.10714em;
      text-transform: uppercase;
      margin: 25px 0;
      -ms-word-wrap: break-word;
      word-wrap: break-word;
  }
  
  .service-subtitle {
    /* font-size: 1.2rem;
    margin-bottom: 30px;
    color: #666; */
        /* font-size: 1.2rem; */
        color: #C79F4F;
        margin-bottom: 40px;
        font-family: Roboto,sans-serif;
        font-size: 18px;
        line-height: 1.15385em;
        letter-spacing: .1em;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1.6em;
        letter-spacing: .6em;
        /* margin: 23px 0 0; */
  }

  @media (max-width: 900px) {
    .servicespage-content h2 {
      font-size: 48px;
    }
  
    .services-container h1 {
      font-size: 40px;
    }
  
    .servicespage-tab {
      padding: 15px;
      font-size: 1rem;
    }
  
    .servicespage-content p,
    .servicespage-content li {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 600px) {
    .servicespage {
      /* margin: 20px; */
    }
  
    .servicespage-content {
      padding: 20px;
    }
  
    
  
    .servicespage-tab {
      padding: 10px 5px; /* Reduce padding to fit more tabs */
      font-size: 0.8rem; /* Reduce font size to fit more tabs */
    }
  
    .servicespage-content h2 {
      font-size: 40px;
    }
  
    .servicespage-content p,
    .servicespage-content li {
      font-size: 0.9rem;
    }
  
    .services-container {
      /* padding: 2% 6% 2% 3%; */
    }
  
    .services-container h1 {
      font-size: 32px;
    }
  
    .service-subtitle {
      font-size: 11px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 426px) {
    .servicespage {
      /* margin: 10px; */
      flex-direction: column;
    }
  
    .servicespage-content {
      padding: 15px;
    }
  
    .servicespage-tabs {
      display: flex;
      flex-direction: column; 
      max-height: 300px; 
      overflow-y: auto; 
    }
  
    .servicespage-tab {
      padding: 8px 4px; 
      font-size: 0.9rem; 
    }
  
    .servicespage-content h2 {
      font-size: 32px;
    }
  
    .servicespage-content p,
    .servicespage-content li {
      font-size: 0.8rem;
    }
  
    .services-container {
      /* padding: 2% 4% 2% 7%; */
    }
  
    .services-container h1 {
      font-size: 28px;
    }
  
    .service-subtitle {
      font-size: 10px;
      margin-bottom: 15px;
    }
  }
  
  