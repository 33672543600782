.carousel-image {
  width: 100%;
  height: 398px;
}

.carousel-caption .banner-2-content {
  font-size: 27px !important;
  line-height: 1.25 !important;
}

.carousel-banner-2-heading {
  line-height: 3 !important;
}

.carousel-slide {
  position: relative;
}

.carousel-caption {
  position: absolute;
  top: 50%;
  left: 4%;
  transform: translateY(-50%);
  /* color: #0033cc; Text color */
  max-width: 500px;
}

.carousel-caption h2 {
  /* font-size: 1.5rem;
    margin: 0;
    letter-spacing: 0.1rem; */
  z-index: 12;
  font-family: Roboto;
  text-transform: uppercase;
  height: auto;
  width: auto;
  color: rgb(5, 27, 57);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 15px;
  letter-spacing: 6px;
  font-weight: 500;
  font-size: 13px;
  backdrop-filter: none;
  filter: none;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
}

.carousel-caption h1 {
  /* font-size: 3rem;
    margin: 10px 0; */
  z-index: 11;
  font-family: Marcellus;
  text-transform: uppercase;
  height: auto;
  width: auto;
  color: rgb(5, 27, 57);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 68px;
  letter-spacing: 0px;
  font-weight: 300;
  font-size: 55px;
  backdrop-filter: none;
  filter: none;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
}

.carousel-caption p {
  /* font-size: 1.2rem;
    margin: 20px 0; */
  z-index: 10;
  font-family: Marcellus;
  height: auto;
  color: rgb(5, 27, 57);
  text-decoration: none;
  white-space: normal;
  width: 532px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 27px;
  letter-spacing: 0px;
  font-weight: 300;
  font-size: 25px;
  backdrop-filter: none;
  filter: none;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
}

.carousel-buttons {
  margin-top: 20px;
}

.slider-desktop {
  display: block !important;
}

.slider-mobile {
  display: none !important;
}

.carousel-button:hover {
  cursor: pointer;
  color: white !important;
  background-color: #051b39;
  transition: 0.3s all;
}

.carousel-button {
  display: inline-block;
  margin-right: 10px;
  padding: 10px 20px;
  background-color: transparent;
  color: #051b39;
  text-decoration: none;
  /* border-radius: 5px; */
  border: 1px solid #051b39;
}

.custom-dots {
  bottom: 15px;
  /* Adjust position as needed */
}

.custom-dot {
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #c79f4f !important;
  /* Diamond color */
}

.slick-dots li.slick-active .custom-dot span {
  color: #c79f4f !important;
  /* Active diamond color */
}

/* Base styles remain unchanged */
@media screen and (max-width: 2500px) {
  .carousel-image {
    height: 100%;
  }
  .carousel-caption h2 {
    letter-spacing: 7px;
  }
  .carousel-caption h1 {
    font-size: 56px;
  }
  /* Large screens (1800px and above) */
  @media (max-width: 1800px) {
    .carousel-caption h1 {
      font-size: 50px;
    }

    .carousel-caption p {
      font-size: 23px;
    }
  }

  /* Medium-large screens (1600px and above) */
  @media (max-width: 1600px) {
    .carousel-caption h1 {
      font-size: 45px;
    }

    .carousel-caption p {
      font-size: 21px;
    }
  }

  /* Medium-large screens (1568px and above) */
  @media (max-width: 1568px) {
    .carousel-caption h1 {
      font-size: 42px;
    }

    .carousel-caption p {
      font-size: 20px;
    }
  }

  /* Medium screens (1200px and above) */
  @media (max-width: 1200px) {
    .carousel-caption h1 {
      font-size: 38px;
    }

    .carousel-caption p {
      font-size: 18px;
    }

    .carousel-caption {
      left: 3%;
    }
  }

  /* Medium-small screens (900px and above) */
  @media (max-width: 900px) {
    .carousel-caption h1 {
      font-size: 32px;
    }

    .carousel-caption p {
      font-size: 14px;
      width: 400px;
    }

    .carousel-caption {
      left: 2%;
    }

    .carousel-caption h2 {
      font-size: 10px;
    }
  }

  /* Small screens (600px and above) */
  @media (max-width: 600px) {
    .carousel-image {
      height: 300px;
    }

    .carousel-caption h1 {
      font-size: 28px;
    }

    .carousel-caption p {
      font-size: 12px;
      width: 300px;
    }

    .carousel-caption {
      left: 1%;
      top: 60%;
    }

    .carousel-caption h2 {
      font-size: 8px;
      letter-spacing: 3px;
    }

    .carousel-button {
      padding: 8px 16px;
      font-size: 12px;
    }

    .custom-dot {
      width: 15px;
      height: 15px;
    }
  }

  @media (max-width: 500px) {
    .slider-desktop {
      display: none !important;
    }

    .slider-mobile .carousel-caption {
      left: 0;
      top: 1.5rem;
      /* right: 0; */
      margin: auto;
      width: 100%;
      text-align: center;
      background: #e3e7eb;
      /* padding: 2rem 0; */
    }

    .slider-mobile .carousel-caption h2 {
      text-align: center;
      margin-top: 3.5rem;
    }

    .slider-mobile .carousel-caption .carousel-banner-2-heading {
      text-align: center;
      margin-top: 3.5rem;
    }

    .slider-mobile .carousel-caption .banner-2-content {
      text-align: center;
      font-size: 14px !important;
      /* margin-top: 2.5rem; */
    }

    .slider-mobile .carousel-caption h1 {
      text-align: center;
      /* margin-top: 2.5rem; */
    }

    .slider-mobile img {
      margin-top: 5rem;
    }

    .slider-mobile {
      display: block !important;
    }
  }

  @media (max-width: 426px) {
    .carousel-caption p {
      font-size: 13px;
      width: 223px;
      line-height: 21px;
    }

    .carousel-caption h1 {
      font-size: 21px;
    }
  }

  @media (max-width: 390px) {
    .carousel-caption h1 {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 320px) {
    .carousel-caption h1 {
      font-size: 16px;
    }

    .carousel-caption p {
      font-size: 11px;
      width: 175px;
      line-height: 21px;
    }
  }

  /* For screens smaller than 320px */
  @media (max-width: 320px) {
    .carousel-caption h2 {
      display: block;
      white-space: normal;
      text-align: center;
      line-height: 1.5rem;
    }

    .carousel-caption h2 .subtitle {
      display: block;
      margin-top: 0.5rem;
      /* Adjust margin as needed */
    }

    .carousel-caption h1 {
      display: block;
      white-space: normal;
      text-align: center;
    }

    .carousel-caption h1 .highlight {
      display: block;
      margin-top: 0.5rem;
      /* Adjust margin as needed */
    }
  }
}
