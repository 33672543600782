body, html {
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
  margin: 0;
  padding: 0;
  background-color: #001026;
  font-family: 'Roboto', sans-serif;
}

/* Apply a reset to remove default padding and margins */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding-top: 68px; /* Adjust this value based on your header height */
  padding-bottom: 40px; /* Adjust this value based on your footer height */
}
* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
.comming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  gap: 1rem;
}
.company-name {
  font-size: 50px;
}
@media screen and (max-width: 475px) {
  .company-name {
    font-size: 40px;
  }
}
@media screen and (max-width: 320px) {
  .company-name {
    font-size: xx-large;
  }
  .comming-soon {
    font-size: larger;
  }
}
