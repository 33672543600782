.container-law {
  display: flex;
  justify-content: center;
  background-color: #001026;
  margin-top: 6rem;
}

.text-section-law {
  display: flex;
  align-items: flex-start;
  margin-right: 1.25rem;
  /* 20px */
  width: 50vw;
}

.diamond-line-law {
  width: 30%;
  height: 0.125rem;
  /* 2px */
  background-color: #C79F4F;
  position: relative;
  margin: 3rem 1.25rem 0 0;
  /* 3rem 2rem 0px 0px */
}

.diamond-line-law::before {
  content: "";
  position: absolute;
  right: -0.625rem;
  /* -10px */
  top: -0.3125rem;
  /* -5px */
  width: 0.625rem;
  /* 10px */
  height: 0.625rem;
  /* 10px */
  background-color: #C79F4F;
  transform: rotate(45deg);
}

.text-content-law {
  max-width: 31.25rem;
  /* 500px */
  color: #fff;
}

.text-content-law p {
  margin: 1.5625rem 0 0;
  /* 25px 0 0 */
  font-size: 1.125rem;
  /* 18px */
  line-height: 1.5em;
  font-weight: 300;
  text-align: justify;
}

.text-content-law h2 {
  font-family: Marcellus, sans-serif;
  font-weight: 400;
  color: #C79F4F;
  font-size: 2.875rem;
  /* 46px */
  line-height: 1.13043em;
  text-transform: uppercase;
  margin: 1.5625rem 0;
  /* 25px 0 */
  word-wrap: break-word;
}

.image-container-law {
  position: relative;
  width: 60vw;
  height: auto;
  margin-top: 5rem;
}

.firm-image-law {
  width: 80%;
  height: auto;
}

.background-image-law {
  position: absolute;
  top: 0;
  right: 5.3125rem;
  /* 85px */
  z-index: 1;
}

.foreground-image-law {
  position: absolute;
  top: 3.125rem;
  /* 50px */
  left: 0;
  z-index: 2;
}

.bottom-section-law {
  background-color: #001026;
  padding: 1% 6%;
  /* padding: 0.125rem 4.8125rem; */
  /* 2px 125px */
  text-align: justify;
  color: #fff;
  font-family: "Roboto", Sans-serif;
  font-size: 1.125rem;
  /* 18px */
  font-weight: 300;
}

.bottom-section-law p {
  margin: 0.625rem 0;
  /* 10px 0 */
  line-height: 1.5em;
}

.strong-text {
  font-weight: bold;
  margin: 0.625rem 0;
  /* 10px 0 */
  font-size: 1.5rem;
  /* 24px */
  color: #C79F4F;
}

.values-list-law {
  /* margin-left: 1.25rem;  */
  font-size: 1.125rem;
  /* 18px */
  font-weight: 300;
  line-height: 1.6875rem;
  /* 27px */
  list-style-type: disc;
  /* padding-left: 1.25rem;  */
}
@media screen and (max-width: 2500px) and (min-width: 2300px) {

  .diamond-line-law {
    width: 10%;
  }

  .top-content-law {
    width: 38vw;
  }

  .bottom-section-law {
    margin-left: -0.0625rem;
    /* -1px */
    margin-top: 7%;
    font-size: 2rem;
  }

  .text-content-law p {
    font-size: 2rem;
  }

  .text-content-law h2{
    font-size: 5.975rem;
  }

  .strong-text {
    font-size: 3.7rem;
  }

  .values-list-law {
    font-size: 2rem;
  }
}


@media screen and (max-width: 2300px) and (min-width: 1800px) {

  .diamond-line-law {
    width: 10%;
  }

  .top-content-law {
    width: 38vw;
  }

  .text-content-law h2{
    font-size: 4.875rem;
  }

  .bottom-section-law {
    margin-left: -0.0625rem;
    /* -1px */
    margin-top: 7%;
    font-size: 2rem;
  }

  .text-content-law p {
    font-size: 2rem;
  }

  .strong-text {
    font-size: 3.7rem;
  }

  .values-list-law {
    font-size: 2rem;
  }
}

@media screen and (max-width: 1800px) and (min-width: 1600px) {
  .diamond-line-law {
    width: 10%;
  }

  .top-content-law {
    width: 38vw;
  }

  .bottom-section-law {
    margin-top: 7%;
    font-size: 1.7rem;
  }

  .text-content-law p {
    font-size: 1.7rem;
  }

  .text-content-law h2{
    font-size: 3.875rem;
  }

  .strong-text {
    font-size: 1.7rem;
  }

  .values-list-law {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1400px) {

  .diamond-line-law {
    width: 10%;
  }

  .bottom-section-law {
    margin-left: -0.0625rem;
    /* -1px */
  }

  .foreground-image-law {
    top: 0px;
  }

  .text-content-law p {
    font-size: 1.2rem;
  }

  .text-content-law h2{
    font-size: 3.875rem;
  }

  .bottom-section-law {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1284px) {

  .diamond-line-law {
    width: 10%;
  }
  .bottom-section-law {
    margin-left: -0.0625rem;
  }

  .foreground-image-law {
    top: 0px;
  }

  .text-content-law p {
    font-size: 1.2rem;
  }

  .text-content-law h2{
    font-size: 3.875rem;
  }

  .bottom-section-law {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 1284px) and (min-width: 1200px) {

  .diamond-line-law {
    width: 63%;
  }
  .bottom-section-law {
    margin-left: -0.0625rem;
  }

  .foreground-image-law {
    top: 0px;
  }

  .text-content-law p {
    font-size: 1.2rem;
  }

  .text-content-law h2{
    font-size: 3.875rem;
  }

  .bottom-section-law {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 1200px) and (min-width: 900px) {

  .diamond-line-law {
    width: 60%;
  }
  
  .bottom-section-law {
    margin-left: -0.0625rem;
    /* -1px */
  }

  .foreground-image-law {
    top: 0px;
  }

  .text-content-law p {
    font-size: 1.2rem;
  }

  .text-content-law h2{
    font-size: 3rem;
  }

  .bottom-section-law {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 900px) {
  .text-content-law h2 {
    font-size: 1.625rem;
    /* 26px */
  }

  .text-content-law p {
    font-size: 1.125rem;
    /* 15px */
  }

  .diamond-line-law {
    width: 78%;
  }

  .bottom-section-law {
    margin-left: -0.1875rem;
    /* -3px */
    margin-top: 1rem;
    font-size: 1.125rem;
    /* 15px */
  }

  .strong-text {
    font-size: 1.5rem;
    /* 19px */
  }
}

@media screen and (max-width: 600px) {
  .container-law {
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }

  .text-section-law {
    width: 100%;
    margin-right: 0;
    align-items: center;
    text-align: center;
  }

  .diamond-line-law {
    margin: -0.4375rem 0.0625rem 0.0625rem -0.1875rem;
    /* -7px 1px 1px -3px */
    width: 0.8125rem;
    /* 13px */
  }

  .text-content-law h2 {
    font-size: 1.375rem;
    /* 22px */
  }

  .text-content-law p {
    font-size: 1.125rem;
    /* 14px */
    padding: 0px 20px;
  }

  .image-container-law {
    width: 100%;
    height: 18.75rem;
    /* 300px */
    position: relative;
    margin-top: 1rem;
  }

  .firm-image-law {
    width: 90%;
    max-width: 18.75rem;
    /* 300px */
  }

  .background-image-law {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 10.875rem;
    /* 174px */
    z-index: 1;
  }

  .foreground-image-law {
    width: 90%;
    max-width: 18.75rem;
    /* 300px */
    position: absolute;
    top: 3.125rem;
    /* 50px */
    left: 6.1875rem;
    /* 131px */
    z-index: 2;
  }

  .bottom-section-law {
    padding: 0px 20px;
    font-size: 1.125rem;
    /* 12px */
    margin-top: -0.1875rem;
    /* -3px */
    margin-left: 0.125rem;
    /* 2px */
    margin-right: 0.0625rem;
    /* 1px */
  }

  .strong-text {
    font-size: 1.5rem;
    /* 18px */
  }

  .values-list-law {
    font-size: 1.125rem;
    /* 14px */
  }
}

@media (max-width: 426px) {
  .diamond-line-law {
    margin: -0.625rem 0.0625rem 0.0625rem -0.1875rem;
    /* -10px 1px 1px -3px */
    width: 1.1875rem;
    /* 19px */
  }

  .text-content-law {
    max-width: 21.4375rem;
    /* 343px */
    color: #fff;
  }

  .image-container-law {
    margin-right: 10.1875rem;
    /* 163px */
  }

  .values-list-law {
    font-size: 1.125rem;
    /* 13px */
  }
}

@media (max-width: 320px) {
  .text-content-law {
    max-width: 15.75rem;
    /* 252px */
    color: #fff;
  }
}

@media (max-width: 376px) {
  .text-content-law p {
    margin: 1.5625rem 1.75rem 0;
    /* 25px 28px 0 */
  }

  .background-image-law {
    left: 7.75rem;
    /* 124px */
  }

  .foreground-image-law {
    left: 5.6875rem;
    /* 107px */
  }
}

@media (max-width: 320px) {
  .diamond-line-law {
    margin: -18.625rem 0.0625rem 0.0625rem -0.1875rem;
  }

  .text-content-law h2 {
    margin: 1.5625rem 2px;
  }
}