.fixed-header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  height: 100px;
}
.schedule-nav-btn:hover {
  color: #ffffff !important;
  background-color: #051b39 !important;
}
.schedule-nav-btn {
  color: #051b39 !important;
  border: 1px solid #051b39 !important;
  border-radius: 0px !important;
  padding: 0.7rem 3rem !important;
  letter-spacing: 0.1em !important;
}
.header {
  display: flex;
  width: 100%;
  height: 100%;
  /* border-bottom: 1px solid #f0f2f4; */
}

.logo-left {
  display: flex;
  align-items: center;
  background-color: #001026 !important;
}

.logoImage {
  width: auto;
  max-height: 100%;
}

.content-header {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #001026;
}

.top-content {
  display: none;
  flex-direction: column;
  height: 30%;
  padding: 5px 20px;
  outline: none;
}

.contact-info-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}

.contact-info-header span {
  font-size: 12px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  color: #051b39;
  font-weight: 300;
}

.contact-info-header i {
  margin-right: 5px;
}

.bottom-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 5px 20px;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.menu a:hover {
  cursor: pointer;
}
.menu a {
  display: flex;
  align-items: center;
  color: #c79f4f;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0 20px;
  text-decoration: none;
  position: relative; /* For positioning the diamond and line */
  padding-left: 30px; /* Space for the diamond */
  font-size: 20px;
}

.menu .diamond {
  width: 12px;
  height: 12px;
  background-color: #c79f4f;
  transform: rotate(45deg); /* Make the square look like a diamond */
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.menu .diamond::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #c79f4f;
  transform: translateY(0%) rotate(135deg);
  transition: width 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.navbar-icon-1 {
  height: 16px !important;
  width: auto !important;
  margin-right: 2px;
}

.menu a:hover .diamond::after {
  width: 20px; /* Length of the line */
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.hamburger .bar {
  width: 100%;
  height: 3px;
  background-color: #ffff;
}

@media (max-width: 768px) {
  .contact-info-header {
    display: none;
  }

  .menu {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu a {
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .fixed-header {
    height: 70px;
  }

  .hamburger {
    display: flex;
    right: 41px;
  }

  .menu {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    flex-direction: column;
    background-color: #001026;
    align-items: flex-start;
    padding: 10px 20px;
  }

  .menu.open {
    display: flex;
  }

  .menu a {
    margin: 2px 32px;
    width: 100%;
    padding: 10px 0;
    font-size: 14px; /* Adjusted font size */
  }

  .menu .diamond {
    width: 6px;
    height: 6px;
    left: -23px;
  }

  .bottom-content {
    justify-content: space-between;
  }
}

@media (min-width: 1800px) and (max-width: 2500px) {
  .menu a {
    font-size: 26px;
  }
}

@media (min-width: 1500px) and (max-width: 1800px) {
  .menu a {
    font-size: 25px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .menu a {
    font-size: 23px;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .menu a {
    font-size: 16px;
  }
}

@media (min-width: 775px) and (max-width: 900px) {
  .menu a {
    font-size: 12px;
  }
}

@media (max-width: 901px) {
  .menu a {
    font-size: 14px;
  }
}
