/* src/components/Footer.css */
.footer-container {
    /* background-color: #7fb6bf; */
    background-color: #001026;
    color: #ffff;
    padding: 40px 20px;
    text-align: center;
    font-family: 'Arial', sans-serif;
    border-top: 1px solid #cecece;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .footer-section {
    margin-bottom: 20px;
  }
  
  .footer-section h2 {
    /* font-size: 1.5rem;
    margin-bottom: 10px;
    border-bottom: 2px solid #051b39;
    display: inline-block;
    padding-bottom: 5px; */

        /* font-size: 2.5rem; */
    /* font-weight: bold; */
    margin: 0px;
    color: #C79F4F;
    
    font-family: Roboto,sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 1.10714em;
    text-transform: uppercase;
    margin: 25px 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
  }
  
  .footer-section p {
    font-size: 1rem;
    line-height: 1.5;
  
  }
  
  .footer-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .footer-link {
    color: #ff6f61;
    text-decoration: none;
    font-weight: bold;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    margin-top: 30px;
    border-top: 1px solid #444;
    padding-top: 10px;
    width: 100%;
  }
  
  .footer-bottom p {
    margin: 0;
    font-size: 0.9rem;
  }
  .footer-bottom p{
    color: #051b39;
  }