.team-container {
  text-align: center;
  padding: 40px 20px;
}

.team-title {
  margin-bottom: 10px;
  color: #C79F4F;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 56px;
  line-height: 1.10714em;
  text-transform: uppercase;
  margin: 25px 0;
  word-wrap: break-word;
}

.team-subtitle {
  color: #C79F4F;
  margin-bottom: 40px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 1.15385em;
  letter-spacing: .1em;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.6em;
  letter-spacing: .6em;
}

.team-members {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;
}

.team-member {
  transition: transform 0.3s, box-shadow 0.3s !important;
  position: relative;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.team-member:hover {
  transform: translateY(-5px);
}

.team-member-position {
  font-size: 1rem;
  color: #777777;
  font-weight: 300;
  margin: 0px;
}

.team-member-name {
  font-family: Marcellus, sans-serif;
  font-weight: 400;
  color: var(--qode-main-color);
  font-family: Roboto, sans-serif;
  font-size: 22px;
  line-height: 1.31818em;
  margin: 0px;
  word-wrap: break-word;
  text-align: left !important;
}

.team-member-name .diamond {
  font-size: 20px;
  margin: 0 5px;
  color: #333;
}

.arrow {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.arrow:hover {
  transform: rotate(-45deg); /* Rotate the arrow to point northeast */
  color: #007bff;
}

.linkedin-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: #b35c45;
  padding: 5px;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  transition: background-color 0.3s;
  border: 1px solid #b35c45;
}

.linkedin-icon:hover {
  background-color: #b35c45;
  color: #ffffff;
}

.expert-team-cards {
  width: 100%;
  display: flex;
  padding: 0px !important;
  justify-content: space-around;
  padding: .5rem !important;
}

.experts-card {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .team-title{
    font-size: 34px;
  }
}