.inquiry-form-container {
  font-family: "Arial, sans-serif";
}

.inquiry-header {
  text-align: center;
}

.inquiry-header h1 {
  margin: 0px;
  color: #c79f4f;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 56px;
  line-height: 1.10714em;
  text-transform: uppercase;
  margin: 25px 0;
  word-wrap: break-word;
}

.inquiry-header p {
  margin-bottom: 30px;
  position: relative;
}

.inquiry-form-subtitle {
  color: #c79f4f;
  margin-bottom: 40px;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  line-height: 1.15385em;
  letter-spacing: 0.1em;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.6em;
  letter-spacing: 0.6em;
}

.inquiry-header p::before,
.inquiry-header p::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #c79f4f; /* Match the orange color from your screenshot */
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  display: inline-block;
  margin: 0 10px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #001026; /* Dark background color */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.text-field {
  margin-bottom: 20px;
}

.text-field .MuiOutlinedInput-root {
  background-color: #001026; /* Darker background color for text fields */
  color: #fff; /* Light text color */
  border-radius: 0;
  border-color: #fff; /* White border color */
}

.text-field .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #fff; /* White border color */
}

.text-field .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #fff; /* White border color */
}

.text-field
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #fff; /* White border color */
}

.text-field-label {
  color: #fff; /* White label color */
}

.text-field-label.Mui-focused {
  color: #fff; /* White label color when focused */
}

.text-field-input::placeholder {
  color: #fff; /* White placeholder color */
  opacity: 1; /* Ensure the placeholder is fully opaque */
}

.half-width {
  width: 48%;
}

.full-width {
  width: 100%;
}

.submit-button {
  align-self: center;
  padding: 10px 30px;
  background-color: #000;
  color: #fff;
}

.inquiry-submit-btn {
  background-color: #c79f4f !important; /* Match the orange color from your screenshot */
  border-radius: 0px !important;
  box-shadow: none !important;
  margin-top: 1rem !important;
}

.map-container {
  width: 100%;
  height: 588px; /* Adjust the height as needed */
  margin: 35px;

  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.address-contact-info {
  width: 100%;
  margin: 35px;
  font-family: "Roboto", Sans-serif;
  color: #fff;
  font-weight: 300;
}

/* Address css  */

.client-details {
  display: flex;
  align-items: baseline;
  line-height: 1.5em;
}

.client-details p {
  font-size: 1rem;
  font-weight: 500;
  font-family: "Roboto", sans-serif;

  
}
.client-details-address {
  font-size: 0.9rem !important;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  text-decoration: none !important;
}

.client-details span:hover {
  text-decoration: underline;
}

.client-details a {
  text-decoration: underline;
  color: white;
  font-size: 0.9rem;
}

.MuiInputLabel-root {
  color: #fff !important; /* White label color */
}

.MuiInputLabel-root.Mui-focused {
  color: #fff !important; /* White label color when focused */
}



/* success message */

.success-message {
  background-color: white;
  border: 1px solid #c3e6cb;
  color: #155724;
  padding: 15px;
  /* border-radius: 5px; */
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
}


.close-message-btn {
  border: 1px solid #155724; 
  color: white; 
  background-color: #001026; 
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 600;
  margin-left: 10px;
}

.close-message-btn:hover {
  background-color: #155724; 
  color: #ffffff; 
}

@media screen and (max-width: 2500px) and (min-width: 1600px) {
  .address-contact-info div p{
    font-size: 1.4rem;
  }

  .address-contact-info div a{
    font-size: 1.4rem !important;
    line-height: 3rem;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1400px) {
  .address-contact-info div p{
    font-size: 1.2rem;
  }

  .address-contact-info div a{
    font-size: 1.2rem !important;
  }

  .client-details{
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .address-contact-info div p{
    font-size: 1rem;
  }

  .address-contact-info div a{
    font-size: 1rem !important;
  }
}